<template>
  <!--<PageHeader :title="title" :items="items" />-->

  <div class="row">
    <div>
      <b-modal ref="edit-modal" hide-footer title="Edit Contact">
        <div
          v-if="alert"
          role="alert"
          aria-live="polite"
          aria-atomic="true"
          :class="changeClasss ? 'alert alert-success' : 'alert alert-danger'"
        >
          {{ alertText }}
        </div>
        <div class="row">
          <div class="col-md-12">
            <b-form-group id="input-group-2" label="Email:" label-for="input-2">
              <b-form-input
                id="email"
                placeholder="Enter Email"
                v-model="email"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <b-form-group
              id="input-group-2"
              label="Phone:"
              type="number"
              label-for="input-2"
            >
              <b-form-input
                id="phone"
                v-model="phone"
                placeholder="Enter Phone"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>
        <div style="float: right">
          <b-button
            ref="save"
            type="submit"
            class="btn btn-success"
            @click="submitForm()"
            >Save</b-button
          >
        </div>
        <button
          style="float: right;margin-right: 16px;"
          type="button"
          class="btn btn-secondary"
          @click="hideModal"
        >
          Close
        </button>
      </b-modal>
    </div>
    <div>
      <b-modal ref="save-modal" hide-footer title="Add Contact">
        <b-form>
          <div class="row">
            <div class="col-md-12">
              <div
                v-if="alert"
                role="alert"
                aria-live="polite"
                aria-atomic="true"
                :class="
                  changeClasss ? 'alert alert-success' : 'alert alert-danger'
                "
              >
                {{ alertText }}
              </div>
              <b-form-group
                id="input-group-2"
                label="Email:"
                label-for="input-2"
              >
                <b-form-input
                  id="email"
                  placeholder="Enter Email"
                  required
                  v-model="email"
                  @keyup="isEmailValid"
                ></b-form-input>
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <b-form-group
                id="input-group-2"
                label="Phone:"
                label-for="input-2"
              >
                <b-form-input
                  id="phone"
                  required
                  v-model="phone"
                  type="number"
                  placeholder="Enter Phone"
                ></b-form-input>
              </b-form-group>
            </div>
          </div>
          <div style="float: right;">
            <a class="btn btn-success" @click="SubmitContact()">Save</a>
          </div>
          <button
            style="float: right;margin-right: 16px;"
            type="button"
            class="btn btn-secondary"
            @click="hideModal"
          >
            Close
          </button>
        </b-form>
      </b-modal>
    </div>
    <div class="col-12">
      <div class="card">
        <div
          v-if="loading"
          class="d-flex justify-content-center align-items-center"
          style="height:100vh"
        >
          <img
            :src="'./images/loader.gif'"
            class="mr-2"
            style="width:100px;height:100px"
          />
        </div>

        <div class="col-12 mt-2" v-if="!loading">
          <div>
            <b-modal ref="my-modal" hide-footer title="Confirmation">
              <div class="d-block">
                <h4>{{ this.modalBodyText }}</h4>
              </div>
              <div style="float: right">
                <button
                  type="button"
                  ref="kt_login_signin_submit"
                  class="btn btn-danger mr-3"
                  @click="changeStatus()"
                >
                  Yes
                </button>
                <button
                  type="button"
                  class="btn btn-secondary"
                  @click="hideModal"
                >
                  No
                </button>
              </div>
            </b-modal>
          </div>
          <template v-if="isShowModel">
            <VerficationModal
              :show="showModal"
              :userObj="userObj"
              :actionType="actionType"
              @changeStatus="singlechangeStatus"
              @deleteRow="deleteRow"
              @closeModal="closeModal"
            />
          </template>
          <!-- <div
            v-if="alert"
            role="alert"
            aria-live="polite"
            aria-atomic="true"
            :class="changeClasss ? 'alert alert-success' : 'alert alert-danger'"
          >
            {{ alertText }}
          </div> -->

          <div
            class="card-header cardHeader-bg"
            style="padding: 14px;margin-bottom:20px"
          >
            <div
              v-if="descriptionAlert"
              role="alert"
              aria-live="polite"
              aria-atomic="true"
              :class="
                changeClasss ? 'alert alert-success' : 'alert alert-danger'
              "
            >
              {{ descriptionAlertText }}
            </div>
            <h4 class="card-title mb-0">Contact Us</h4>
          </div>

          <div>
            <b-form v-on:submit.prevent="DescriptionForm">
              <b-form-group
                id="input-group-2"
                label="Description:"
                label-for="input-2"
              >
                <b-form-textarea
                  id="textarea"
                  v-model="description"
                  placeholder="Enter Description here..."
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>
              </b-form-group>

              <b-button
                ref="save"
                type="submit"
                class="btn btn-success"
                style="float: right"
                >Save</b-button
              >
            </b-form>
          </div>

          <button
            style="margin-left: 12px; margin-top:5%"
            class="btn btn-success rounded-pill mb-2"
            @click="openModal()"
          >
            Add
          </button>
          <button
            style="float: right; margin-top:5%"
            class="btn btn-danger btn btn-danger rounded-pill mb-2"
            data-toggle="modal"
            data-target="#modalId"
            @click="confirmationBulkAction('delete')"
          >
            Delete
          </button>
          <div class="card-header cardHeader-bg" style="padding: 14px;">
            <h4 class="card-title mb-0">Contacts</h4>
          </div>
        </div>
        <div class="card-body" v-if="!loading">
          <div class="row">
            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_length" class="dataTables_length">
                <label class="d-inline-flex align-items-center">
                  Show
                  <b-form-select
                    v-model="perPage"
                    size="sm"
                    :options="pageOptions"
                  ></b-form-select>
                  entries
                </label>
              </div>
            </div>
            <!-- Search -->
            <div class="col-sm-12 col-md-6">
              <div
                id="tickets-table_filter"
                class="dataTables_filter text-md-right"
              >
                <label class="d-inline-flex align-items-center">
                  Search:
                  <b-form-input
                    v-model="filter"
                    type="search"
                    placeholder="Search..."
                    class="form-control form-control-sm ml-2"
                  ></b-form-input>
                </label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 table-responsive mb-0">
              <b-table
                :items="loans"
                :fields="fields"
                :updatedIsSelectAll="isSelectAll"
                @updateStatus="updateStatus"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                show-empty
                empty-text="No record found"
                empty-filtered-text="No search result found"
              >
                <template #head(id)>
                  <input v-model="isSelectAll" type="checkbox" />
                </template>

                <template v-slot:cell(id)="loans">
                  <input
                    type="checkbox"
                    :value="loans.item.id"
                    v-model="checkedNames"
                  />
                </template>

                <template v-slot:cell(status)="loans">
                  <i
                    v-if="loans.item.status == '1'"
                    style="color:green;cursor: pointer;"
                    class="fas fa-check"
                    @click="singlActiveDeactive(loans)"
                  ></i
                  >&nbsp;
                  <i
                    v-if="loans.item.status == '0'"
                    style="color:red;cursor: pointer;"
                    class="fas fa-times"
                    @click="singlActiveDeactive(loans)"
                  ></i>
                </template>

                <template v-slot:cell(actions)="loans">
                  <i
                    class="fas fa-edit"
                    style="color:green;cursor: pointer;"
                    @click="editModal(loans)"
                  ></i
                  >&nbsp;
                  <i
                    style="color:red;cursor: pointer;"
                    class="fas fa-trash-alt"
                    @click="deleteUser(loans)"
                  ></i>
                </template>
                <!-- <template v-slot:cell(status)="loans">
                  <button
                    v-bind:class="changeClass(loans)"
                    @click="singlActiveDeactive(loans)"
                  >
                    {{ changeVerfiedtext(loans) }}</button
                  >&nbsp;
                </template>
                <template v-slot:cell(actions)="loans">
                  <button
                    v-bind:class="'btn btn-success btn-sm'"
                    @click="editModal(loans)"
                  >
                    Edit</button
                  >&nbsp;
                  <button
                    v-bind:class="'btn btn-danger btn-sm'"
                    @click="deleteUser(loans)"
                  >
                    Delete</button
                  >&nbsp;
                </template> -->
              </b-table>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <div
                class="dataTables_paginate paging_simple_numbers float-right"
              >
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                  ></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VerficationModal from "./VerficationModal";
import axios from "axios";
export default {
  page: {
    title: "Collateral",
  },

  components: {
    VerficationModal,
  },
  data() {
    return {
      loans: [],
      id: "",
      alert: false,
      descriptionAlert: false,
      loading: true,
      alertText: "",
      descriptionAlertText: "",
      isModel: false,
      modalBodyText: "",
      actionType: "",
      changeClasss: false,
      modalTitle: "",
      buttonStatus: "",
      title: "Users",
      showModal: false,
      checkedNames: [],
      contactArray: [],
      customerId: "",
      userObj: {},
      isShowModel: false,
      totalRows: 1,
      currentPage: 1,
      email: "",
      phone: "",
      description: "",
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      isSelectAll: false,
      showDeleteModal: false,
      apiResponceError: false,
      emailValidation: false,

      sortDesc: true,
      fields: [
        { key: "id", label: "Select", sortable: true },
        { key: "email", label: "Email", sortable: true },
        { key: "phone", label: "Phone Number", sortable: true },
        { key: "actions" },
      ],
    };
  },
  computed: {
    rows() {
      return this.loans.length;
    },
  },
  watch: {
    isSelectAll: function(val) {
      if (val) {
        this.checkedNames = this.$refs.selectableTable.map((x) => x.id);
      } else {
        this.checkedNames = [];
      }
    },
  },
  methods: {
    isEmailValid: function isEmailValid() {
      this.apiResponceError = false;
      this.emailValidation = false;
      var regex = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
      var responce = regex.test(this.email);
      if (responce) {
        this.alert = false;
      } else {
        this.alertText = "Please enter valid email address";
        this.changeClasss = false;
        this.alert = true;
        this.emailValidation = true;
      }
      return responce;
    },
    openModal() {
      this.$refs["save-modal"].show();
    },

    validationMessage(message) {
      this.alert = true;
      this.alertText = message;
      this.changeClasss = false;
    },
    submitForm() {
      try {
        if (this.phone.length > 0 && this.phone.length < 11) {
          this.validationMessage(
            "Phone Number must be at least 11 characters "
          );
          return false;
        }
        if (this.email === "") {
          this.validationMessage("Please select email");
          return false;
        }
        let data = {
          id: this.id,
          email: this.email,
          phone: this.phone,
        };

        const headers = {
          Authorization: "Bearer " + localStorage.getItem("id_token"),
        };
        axios
          .post(this.$path + "/contact/addcontact", data, {
            headers,
          })
          .then((response) => {
            if (response.data.code == 200) {
              // this.$refs["edit-modal"].hide();
              this.getAllRecord();
              this.alert = true;
              this.changeClasss = true;
              this.alertText = response.data.message;
            }
            if (response.data.code == 422) {
              this.alert = true;
              this.alertText = response.data.message;
            }
            if (response.data.code == 501) {
              this.$router.push({ name: "login" });
            }
          })
          .catch(({ response }) => {
            if (response) {
              alert("Technical Issue");
            }
          });
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
      }
    },
    SubmitContact() {
      if (this.emailValidation) {
        return false;
      }
      if (this.phone.length > 0 && this.phone.length < 11) {
        this.alert = true;
        this.validationMessage("Phone Number must be at least 11 characters ");
        return false;
      }
      if (this.email === "") {
        this.validationMessage("Please select email");
        return false;
      }

      try {
        const form = {
          email: this.email,
          phone: this.phone,
        };
        const headers = {
          Authorization: "Bearer " + localStorage.getItem("id_token"),
        };

        axios
          .post(this.$path + "/contact/addcontact", form, {
            headers,
          })
          .then((response) => {
            if (response.data.code == 200) {
              // this.$refs["save-modal"].hide();
              this.getAllRecord();
              this.alert = true;
              this.changeClasss = true;
              this.alertText = response.data.message;
              this.email = "";
              this.phone = "";
            }
            if (response.data.code == 422) {
              this.alert = true;
              this.alertText = response.data.message;
            }
            if (response.data.code == 501) {
              this.$router.push({ name: "login" });
            }
          })
          .catch(({ response }) => {
            if (response) {
              alert("Technical Issue");
            }
          });
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
      }
    },
    DescriptionForm() {
      try {
        const form = {
          description: this.description,
        };
        const headers = {
          Authorization: "Bearer " + localStorage.getItem("id_token"),
        };
        axios
          .post(this.$path + "/contact/store-description", form, {
            headers,
          })
          .then((response) => {
            if (response.data.code == 200) {
              this.getAllRecord();
              // this.$router.push({ name: "contactus" });
              this.descriptionAlert = true;
              this.changeClasss = true;
              this.descriptionAlertText = response.data.message;
            }
            if (response.data.code == 422) {
              this.descriptionAlert = true;
              this.descriptionAlertText = response.data.message;
            }
            if (response.data.code == 501) {
              this.$router.push({ name: "login" });
            }
          })
          .catch(({ response }) => {
            if (response) {
              alert("Technical Issue");
            }
          });
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
      }
    },

    confirmationBulkAction(status) {
      if (this.checkedNames.length > 0) {
        if (status == "delete") {
          this.status = "delete";
          this.modalBodyText = "Do You Want To Delete This Record?";
        }
        if (status == "active") {
          this.status = "active";
          this.modalBodyText = "Do You Want To Active This Record?";
        }
        if (status == "deactive") {
          this.status = "deactive";
          this.modalBodyText = "Do You Want To Deactive This Record?";
        }
        this.$refs["my-modal"].show();
      } else {
        this.alert = true;
        this.alertText = "Please Select checkbox";
        this.hideAlert();
      }
    },
    closeModal(value) {
      this.isShowModel = value;
      this.alert = true;
      this.changeClasss = true;
    },

    deleteRow(loans) {
      let index = this.loans.findIndex((x) => x.id === loans.item.id);
      if (index !== -1) {
        this.loans.splice(index, 1);
      }
      this.changeClasss = true;
      this.descriptionAlert = true;
      this.descriptionAlertText = "Record deleted successfully";
      this.hideAlert();
    },

    singlechangeStatus(getUser) {
      let index = this.loans.findIndex((x) => x.id === getUser.id);
      if (index !== -1) {
        this.loans[index].status = getUser.status;
      }
      this.alert = true;
      this.alertText = "Record Updated Successfully";
      this.hideAlert();
    },

    updateStatus(data) {
      this.isSelectAll = data;
    },

    hideModal() {
      this.$refs["edit-modal"].hide();
      this.$refs["save-modal"].hide();
    },
    hideAlert() {
      setTimeout(() => (this.alert = false), 2000);
    },

    deleteUser(object) {
      this.showModal = true;
      this.isShowModel = true;
      this.userObj = object;
      this.actionType = "delete";
    },
    singlActiveDeactive(object) {
      this.showModal = true;
      this.isShowModel = true;
      this.userObj = object;
      if (this.userObj.item.status == "1") {
        this.actionType = "active";
      }
      if (this.userObj.item.status == "0") {
        this.actionType = "deactive";
      }
    },
    addClassToButton() {
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
    },
    changeStatus() {
      try {
        this.addClassToButton();
        this.showDeleteModal = true;
        const data = { status: this.status, bluckid: this.checkedNames };
        const headers = {
          Authorization: "Bearer " + localStorage.getItem("id_token"),
        };
        axios
          .post(this.$path + "/contact/changestatus", data, {
            headers,
          })
          .then((response) => {
            if (response.data.code == 200) {
              this.$refs["my-modal"].hide();
              this.getAllRecord();
              this.alert = true;
              this.alertText = "Record Updated Successfully";
              this.checkedNames = [];
              this.hideAlert();
            }
          });
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
      }
    },

    getAllRecord() {
      const headers = {
        Authorization: "Bearer " + localStorage.getItem("id_token"),
      };
      try {
        axios
          .post(this.$path + "/contact/contactlist", "", {
            headers,
          })
          .then((response) => {
            if (response.data.code == 200) {
              this.loans = response.data.data;
              this.description = response.data.data[0]["description"];
              this.loading = false;
            } else {
              this.loading = false;
            }
          });
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
      }
    },
    changeVerfiedtext(loans) {
      if (loans.item.status == 1) {
        return "Active";
      } else {
        return "Deactive";
      }
    },
    changeClass(loans) {
      if (loans.item.status == 1) {
        return "btn btn-info btn-sm";
      } else {
        return "btn btn-primary btn-sm";
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    addLoan() {
      this.$router.push({ name: "add-loan", params: { is_edit: false } });
    },

    editModal(loans) {
      this.id = loans.item.id;
      this.$refs["edit-modal"].show();
      (this.email = loans.item.email),
        (this.phone = loans.item.phone),
        (this.description = loans.item.description);
    },
  },
  mounted() {
    document.title = "Contact";
    this.totalRows = this.loans.length;
    this.getAllRecord();
  },
};
</script>
<style scoped>
#add-loan-btn {
  padding: 8px 25px 8px 25px;
}
.btn-success {
  border-color: #5cb700;
  background-color: rgb(92, 183, 0);
}
.btn.btn-success:hover {
  border-color: #5cb700 !important;
  background-color: rgb(92, 183, 0) !important;
}
.btn-success:active {
  border-color: #5cb700 !important;
  background-color: rgb(92, 183, 0) !important;
}

.btn-success:focus {
  border-color: #5cb700 !important;
  background-color: rgb(92, 183, 0) !important;
}
</style>
